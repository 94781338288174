import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Pavement Analysis Design`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1B-59hRu3H8aRXTy3t-H_b0IIVwan30cY"
      }}>{`Download: Pavement analysis and design by Yang H. Huang`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1LiVYbpTuoF4YEp4ar2OyR2I2ZX1GyL_x"
      }}>{`Download: Solution manual Pavement analysis and design by Yang H. Huang`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1LgD-UyO_qLjiS19XsoEPwzgrdAlLxJbJ"
      }}>{`Download: Overseas road note 31 `}</a></p>
    <h3>{`Solution manual hand written (Pavement analysis and design by Yang H. Huang)`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/assignment-ch1.pdf"
      }}>{`assignment ch1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/assignment-ch-1246-pavement.pdf"
      }}>{`assignment ch 1,2,4,6 pavement`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/assignment-ch-2-by-software.pdf"
      }}>{`assignment ch 2 by software`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/assignment-ch-11-12.pdf"
      }}>{`assignment ch 11, 12`}</a></p>
    <h3>{`Excel sheets`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/Pavement-analysis-and-design-rigid-pavement-design.xlsx"
      }}>{`Pavement analysis and design rigid pavement design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/prob-7.1-7.2.xlsx"
      }}>{`prob 7.1, 7.2`}</a></p>
    <h3>{`Term project`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/pavement-project-assignment.pdf"
      }}>{`pavement project assignment`}</a></p>
    <h3>{`Kenpave software files`}</h3>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1pRYgANl_zRBXDlWAPxpn2tgWTg-z5DyH"
      }}>{`compressed (solved problems)`}</a></p>
    <h2>{`Lectures`}</h2>
    <p>{`Lectures from book “Pavement analysis and design by Yang H. Huang”`}</p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-1.pdf"
      }}>{`PA&D-Lec-1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-2.pdf"
      }}>{`PA&D-Lec-2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-3.pdf"
      }}>{`PA&D-Lec-3`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-4.pdf"
      }}>{`PA&D-Lec-4`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-5.pdf"
      }}>{`PA&D-Lec-5`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-6.pdf"
      }}>{`PA&D-Lec-6`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-7.pdf"
      }}>{`PA&D-Lec-7`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-8.pdf"
      }}>{`PA&D-Lec-8`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-9.pdf"
      }}>{`PA&D-Lec-9`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-10.pdf"
      }}>{`PA&D-Lec-10`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-11.pdf"
      }}>{`PA&D-Lec-11`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-12.pdf"
      }}>{`PA&D-Lec-12`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-13.pdf"
      }}>{`PA&D-Lec-13`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/PAD-Lec-14.pdf"
      }}>{`PA&D-Lec-14`}</a></p>
    <h2>{`Vehicle classification and axle loads classification`}</h2>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/Ag-Vehicle-Weights-And-Dimensions-Leaflet_Sept-2015.pdf"
      }}>{`Avg Vehicle Weights And Dimensions Leaflet`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/NHA-general-specifications.pdf"
      }}>{`NHA general specifications`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/nha-general-specification-e-35-volum-ii.pdf"
      }}>{`NHA-general-specification`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/03/Road-Accident-Analysis-NTRC-304May1990.pdf"
      }}>{`Road Accident Analysis (NTRC-304)(May,1990)`}</a></p>
    <span style={{
      "color": "red"
    }}>Some Content is also available</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      